import { Authenticator } from "@10duke/web-client-pkce";

/**
 * Creates authenticator for handling authentication requests.
 * @param loginCallbackPath Internal route for login callback, without leading slash.
 *    Used by this method for constructing OAuth redirect_uri.
 */
export default function createAuthenticator(
  loginCallbackPath: string = "logincb"
): Authenticator {
  const srvBase = new URL(
    process.env.REACT_APP_SRV_BASE || "https://localhost:48443"
  );
  const clientId = process.env.REACT_APP_CLIENT_ID || "duke-test-client";
  const authzUrl = new URL(
    process.env.REACT_APP_SRV_OAUTH_AUTHZ_URL || "/user/oauth20/authz/v2",
    srvBase
  );
  const tokenUrl = new URL(
    process.env.REACT_APP_SRV_OAUTH_TOKEN_URL || "/user/oauth20/token",
    srvBase
  );
  const sloUrl = new URL(
    process.env.REACT_APP_SRV_SLO_URL || "/user/oauth20/signout",
    srvBase
  );
  const jwksUrl = new URL(
    process.env.REACT_APP_SRV_JWKS_URL || "/user/.well-known/jwks.json",
    srvBase
  );
  const scope =
    process.env.REACT_APP_OAUTH_SCOPE ||
    "openid profile email https://apis.10duke.com/auth/openidconnect/organization https://apis.10duke.com/auth/openidconnect/user.properties";

  const currentUrl = new URL(window.location.href);
  const appBase = process.env.REACT_APP_BASE || "/";
  const redirectUrlAsString = `${currentUrl.protocol}//${currentUrl.host}${appBase}${loginCallbackPath}`;
  const redirectUrl = new URL(redirectUrlAsString);

  return new Authenticator(
    authzUrl,
    tokenUrl,
    sloUrl,
    jwksUrl,
    clientId,
    redirectUrl,
    scope
  );
}
