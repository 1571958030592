import React, { useMemo, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

import { Authentication } from "@10duke/web-client-pkce";
import AuthenticationSetter from "../../auth/AuthenticationSetter";
import HomePage from "../home-page";
import LoginPage from "../login-page";
import LoginCbPage from "../login-cb-page";


/**
 * Authentication related properties for components
 */
export interface AuthProps {
    /**
     * Authentication status
     */
    authentication?: Authentication;
    /**
     * Callback for updating authentication status
     * @param authn New status to set
     * @param navigateTo Path to navigate to after update
     */
    setAuthentication: AuthenticationSetter;
}

/**
 * Main app, responsible for storing authentication status and routing as well as main navigation.
 * @constructor
 */
function App() {
    const location = useLocation();
    const navigate = useNavigate();
    const [authentication, setAuthenticationState] = useState<
        Authentication | undefined
    >(undefined);

    const setAuthentication = useMemo(
        () => (a?: Authentication, navigateTo?: string) => {
            setAuthenticationState(a);
            if (
                a &&
                (location.pathname.endsWith("/login") ||
                    location.pathname.endsWith("/logincb"))
            ) {
                // Clear the data stored when login started, has to be here as the actual login callback is invoked
                // twice (React.strictMode) with development builds. And the second pass would fail without this data.
                if (location.pathname.endsWith("/logincb")) {
                    if (localStorage.getItem("startLoginState")) {
                        localStorage.removeItem("startLoginState");
                    }
                }
                if (navigateTo) {
                    navigate(navigateTo);
                } else {
                    navigate("/");
                }
            }
        },
        [setAuthenticationState, navigate, location]
    );

    const authProps: AuthProps = { authentication, setAuthentication };

    return (
        <>
            <main
                role="main"
                className="container-fluid flex-grow-1 d-flex flex-column"
            >
                <Routes>
                    <Route path="/" element={
                        <HomePage
                            {...authProps}
                        />
                    } />
                    <Route path="/login" element={
                        <LoginPage {...authProps} />
                    } />
                    <Route path="/logincb" element={
                        <LoginCbPage {...authProps} />
                    } />
                </Routes>
            </main>
        </>
    );
}

export default App;
