import React, { useEffect } from "react";
import createAuthenticator from "../../auth/createAuthenticator";
import { AuthProps } from "../app";
import useQuery from "../../utils/use-query";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

interface LoginProps extends AuthProps {}

async function startLogin(state?: string) {
  const authenticator = createAuthenticator();
  const startLoginState = await authenticator.startLogin(state);
  localStorage.setItem("startLoginState", JSON.stringify(startLoginState));
  window.location.href = startLoginState.url.toString();
}

/**
 * Triggers the login process, renders just a processing indicator as the login process will cause redirects,
 * eventually to  login-cb-page
 * @param props
 * @constructor
 */
function LoginPage(props: LoginProps) {
  const { authentication } = props;

  let query = useQuery();
  const next = query.get("then");

  useEffect(() => {
    if (!authentication) {
      startLogin(next ? next : undefined);
    }
  }, [authentication, next]);

  return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
      }}>
      <CircularProgress/>
      </Box>
  );
}

export default LoginPage;
