import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import App from './components/app/App';
import "./static/fonts/fonts.css";
import {createTheme, ThemeProvider} from "@mui/material/styles";

const theme = createTheme({
    typography: {
        fontFamily: [
            'Inter',
            'Inter Semi Bold',
            'Inter Bold'
        ].join(','),
    },});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <Router>
          <ThemeProvider theme={theme}>
              <App/>
          </ThemeProvider>
      </Router>
  </React.StrictMode>
);
