import React, {useEffect, useState} from "react";
import {AuthProps} from "../app";
import {useNavigate, useLocation} from "react-router-dom";
import {Box, Typography} from "@mui/material";

interface HomeProps extends Pick<AuthProps, 'authentication'> {
}

/**
 * Renders the home page content
 * @param props
 * @constructor
 */
function HomePage(props: HomeProps) {
  const {
    authentication
  } = props;
  const navigate = useNavigate();
    const location = useLocation();
    const [count, setCount] = useState(5);

    useEffect(() => {
        if(authentication) {
            window?.opener?.postMessage(authentication.getAccessTokenResponse(), process.env.REACT_APP_WINDOW_OPENER);
        }
    }, [authentication]);

    useEffect(() => {
        if(authentication) {
        const countdown = setTimeout(() => {
            if (count > 0) {
                setCount(count - 1);
            } else {
                window.close(); // Close the window when countdown reaches 0
            }
        }, 1000); // Update every second

        // Clear timeout if component unmounts or countdown reaches 0
        return () => clearTimeout(countdown);
        }
    }, [authentication, count]);

    useEffect(() => {
        if(!authentication) {
            navigate(`/login?then=${location.pathname}`);
        }
    }, [authentication, navigate, location.pathname]);

  return (
      <>
          {authentication && (
              count !== 0 ? (
              <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                }}>
                  <Typography
                      variant={'subtitle1'}
                      sx={{
                          color: '#373F46',
                          fontFamily: 'Inter',
                          fontSize: '22px',
                          fontWeight: 600,
                          lineHeight: '28px',
                          letterSpacing: '0.1px',
                          textAlign: 'left',
                          marginBottom: '8px'
                      }}
                  >
                      Logging you in...
                  </Typography>
                  <Typography
                    variant={'body2'}
                    sx={{
                        color: '#505A63',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '20px',
                        letterSpacing: '0.25px',
                        textAlign: 'left',
                        marginBottom: '8px'
                    }}
                  >
                      This window will close automatically in
                  </Typography>
              <Typography
              variant={'body2'}
              sx={{
                  color: '#505A63',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '20px',
                  letterSpacing: '0.25px',
                  textAlign: 'left'
              }}
                 >
                    {count}{count === 1 ? ' second' : ' seconds'}
                </Typography>
            </Box>
          ) :
          (
              <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100vh'
              }}>
                  <Typography
                      variant={'subtitle1'}
                      sx={{
                          color: '#373F46',
                          fontFamily: 'Inter',
                          fontSize: '22px',
                          fontWeight: 600,
                          lineHeight: '28px',
                          letterSpacing: '0.1px',
                          textAlign: 'left',
                          marginBottom: '8px'
                      }}
                  >
                      You are now logged in.
                  </Typography>
                  <Typography
                      variant={'body2'}
                      sx={{
                          color: '#505A63',
                          fontFamily: 'Inter',
                          fontSize: '14px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '0.25px',
                          textAlign: 'left',
                          marginBottom: '8px'
                      }}
                  >
                      You may close this window.
                  </Typography>
              </Box>
            )
          )
          }
      </>
  );
}

export default HomePage;
